var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.loading,
          expression: "loading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      attrs: { id: "carpooling_profile_page" }
    },
    [
      _c("div", { ref: "google_map", attrs: { id: "google_map" } }),
      _c("div", { attrs: { id: "info_route" } }, [
        _c(
          "div",
          { staticClass: "container", staticStyle: { "max-width": "600px" } },
          [
            _c(
              "div",
              { staticClass: "carpool-profile-info" },
              [
                _c("el-avatar", { attrs: { size: 70, src: _vm.user_photo } }),
                _c("div", { staticClass: "carpool-profile-info-info" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.profile.first_name) +
                        " " +
                        _vm._s(_vm.profile.last_name)
                    )
                  ]),
                  _vm.profile.email_verified && _vm.profile.phone_verified
                    ? _c("div", [
                        _c(
                          "span",
                          [
                            _c("font-awesome-icon", {
                              staticStyle: { color: "green" },
                              attrs: { icon: ["fas", "check"] }
                            }),
                            _vm._v(" Email & phone verified")
                          ],
                          1
                        )
                      ])
                    : _vm.profile.email_verified
                    ? _c("div", [
                        _c(
                          "span",
                          [
                            _c("font-awesome-icon", {
                              staticStyle: { color: "green" },
                              attrs: { icon: ["fas", "check"] }
                            }),
                            _vm._v(" Email verified")
                          ],
                          1
                        ),
                        _c(
                          "span",
                          [
                            _c("font-awesome-icon", {
                              staticStyle: { color: "gray" },
                              attrs: { icon: ["fas", "times"] }
                            }),
                            _vm._v(" Phone not verified")
                          ],
                          1
                        )
                      ])
                    : _vm.profile.phone_verified
                    ? _c("div", [
                        _c(
                          "span",
                          [
                            _c("font-awesome-icon", {
                              staticStyle: { color: "gray" },
                              attrs: { icon: ["fas", "times"] }
                            }),
                            _vm._v(" Email not verified")
                          ],
                          1
                        ),
                        _c(
                          "span",
                          [
                            _c("font-awesome-icon", {
                              staticStyle: { color: "green" },
                              attrs: { icon: ["fas", "check"] }
                            }),
                            _vm._v(" Phone verified")
                          ],
                          1
                        )
                      ])
                    : _c("div", [
                        _c(
                          "span",
                          [
                            _c("font-awesome-icon", {
                              staticStyle: { color: "gray" },
                              attrs: { icon: ["fas", "times"] }
                            }),
                            _vm._v(" Email & phone not verified")
                          ],
                          1
                        )
                      ])
                ])
              ],
              1
            ),
            _vm.profile.carpooling_details
              ? _c("div", { staticClass: "carpool-profile-description" }, [
                  !_vm.read_more_activated &&
                  _vm.profile.carpooling_details.description.length >
                    _vm.max_description_length
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            (_vm.profile.carpooling_details.description || "")
                              .slice(0, _vm.max_description_length)
                              .trim()
                          ) + "... "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "highlighted",
                            on: {
                              click: function($event) {
                                _vm.read_more_activated = true
                              }
                            }
                          },
                          [_vm._v(" read more")]
                        )
                      ])
                    : _c("span", [
                        _vm._v(
                          _vm._s(_vm.profile.carpooling_details.description)
                        )
                      ]),
                  _vm.profile.carpooling_details &&
                  _vm.profile.carpooling_details.contribution &&
                  _vm.isNumber(_vm.profile.carpooling_details.contribution)
                    ? _c("div", { staticClass: "wanted-contribution" }, [
                        _c("span", { staticClass: "dark" }, [
                          _vm._v("Wanted contribution: ")
                        ]),
                        _c("span", [
                          _vm._v(
                            "€" +
                              _vm._s(
                                _vm.profile.carpooling_details.contribution
                              )
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.profile.carpooling_friends &&
                  _vm.profile.carpooling_friends[_vm.$store.getters.user.id] ==
                    "accepted"
                    ? _c("div", { staticClass: "contact-details" }, [
                        _c("span", { staticClass: "dark" }, [
                          _vm._v("Contact:")
                        ]),
                        _c("div", [
                          _c("span", [
                            _vm._v(_vm._s(_vm.phone_number.formatNational()))
                          ]),
                          _c("br"),
                          _c("span", [_vm._v(_vm._s(_vm.profile.email))])
                        ])
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _c("div", { staticClass: "carpooling-profile-details" }, [
              _c("div", { staticClass: "details-days-of-week" }, [
                _c(
                  "span",
                  {
                    attrs: {
                      "day-active": _vm.profile.commuting_days_of_week.monday
                    }
                  },
                  [_vm._v("Mon")]
                ),
                _c(
                  "span",
                  {
                    attrs: {
                      "day-active": _vm.profile.commuting_days_of_week.tuesday
                    }
                  },
                  [_vm._v("Tue")]
                ),
                _c(
                  "span",
                  {
                    attrs: {
                      "day-active": _vm.profile.commuting_days_of_week.wednesday
                    }
                  },
                  [_vm._v("Wed")]
                ),
                _c(
                  "span",
                  {
                    attrs: {
                      "day-active": _vm.profile.commuting_days_of_week.thursday
                    }
                  },
                  [_vm._v("Thu")]
                ),
                _c(
                  "span",
                  {
                    attrs: {
                      "day-active": _vm.profile.commuting_days_of_week.friday
                    }
                  },
                  [_vm._v("Fri")]
                ),
                _c(
                  "span",
                  {
                    attrs: {
                      "day-active": _vm.profile.commuting_days_of_week.saturday
                    }
                  },
                  [_vm._v("Sat")]
                ),
                _c(
                  "span",
                  {
                    attrs: {
                      "day-active": _vm.profile.commuting_days_of_week.sunday
                    }
                  },
                  [_vm._v("Sun")]
                )
              ]),
              _c("div", { staticClass: "details-times" }, [
                _c("div", [
                  _c("span", { staticClass: "info-desc" }, [_vm._v("Go")]),
                  _c("span", { staticClass: "info-data" }, [
                    _vm._v(_vm._s(_vm.go_leaving_time))
                  ])
                ]),
                _c("div", [
                  _c("span", { staticClass: "info-desc" }, [_vm._v("Back")]),
                  _c("span", { staticClass: "info-data" }, [
                    _vm._v(_vm._s(_vm.back_leaving_time))
                  ])
                ])
              ]),
              _vm.action_needed != "none"
                ? _c(
                    "div",
                    { attrs: { id: "floating-button-request" } },
                    [
                      _c(
                        "el-button",
                        {
                          class: {
                            "secondary-color":
                              _vm.action_needed != "pending_request",
                            "pending-button":
                              _vm.action_needed == "pending_request"
                          },
                          attrs: {
                            type: "primary",
                            "data-action": _vm.action_needed,
                            round: ""
                          }
                        },
                        [
                          _vm.action_needed == "send_message"
                            ? _c(
                                "div",
                                {
                                  staticClass: "button-content",
                                  on: {
                                    click: function($event) {
                                      return _vm.onActionClicked(
                                        _vm.action_needed
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("font-awesome-icon", {
                                    staticStyle: {
                                      "font-size": "18px",
                                      "margin-right": "3px"
                                    },
                                    attrs: { icon: ["fab", "whatsapp"] }
                                  }),
                                  _c("span", [_vm._v(" Send WhatsApp")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.action_needed == "send_request"
                            ? _c(
                                "div",
                                {
                                  staticClass: "button-content",
                                  on: {
                                    click: function($event) {
                                      return _vm.onActionClicked(
                                        _vm.action_needed
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("font-awesome-icon", {
                                    staticStyle: {
                                      "font-size": "16px",
                                      "margin-right": "3px"
                                    },
                                    attrs: { icon: ["fas", "user-plus"] }
                                  }),
                                  _c("span", [_vm._v(" Send request")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.action_needed == "accept_request"
                            ? _c(
                                "div",
                                {
                                  staticClass: "button-content",
                                  on: {
                                    click: function($event) {
                                      return _vm.onActionClicked(
                                        _vm.action_needed
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("font-awesome-icon", {
                                    staticStyle: {
                                      "font-size": "16px",
                                      "margin-right": "3px"
                                    },
                                    attrs: { icon: ["fas", "user-check"] }
                                  }),
                                  _c("span", [_vm._v(" Accept request")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.action_needed == "pending_request"
                            ? _c(
                                "div",
                                {
                                  staticClass: "button-content",
                                  on: {
                                    click: function($event) {
                                      return _vm.onActionClicked(
                                        _vm.action_needed
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("font-awesome-icon", {
                                    staticStyle: {
                                      "font-size": "16px",
                                      "margin-right": "3px"
                                    },
                                    attrs: { icon: ["fas", "spinner"] }
                                  }),
                                  _c("span", [_vm._v(" Pending request")])
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }