<template>
  <div v-loading.fullscreen.lock="loading" id="carpooling_profile_page">

      <div id="google_map" ref="google_map"></div>

      <div id="info_route">
        <div style="max-width: 600px" class="container">
          <div class="carpool-profile-info">
            <el-avatar :size="70" :src="user_photo"></el-avatar>
            <div class="carpool-profile-info-info">
              <span>{{ profile.first_name }} {{ profile.last_name }}</span>

              <!-- If phone and email verified -->
            <div v-if="profile.email_verified && profile.phone_verified">
              <span><font-awesome-icon style="color: green" :icon="['fas', 'check']" />&nbsp;Email & phone verified</span>
            </div>

            <!-- If only email verified -->
            <div v-else-if="profile.email_verified">
              <span><font-awesome-icon style="color: green" :icon="['fas', 'check']" />&nbsp;Email verified</span>
              <span><font-awesome-icon style="color: gray" :icon="['fas', 'times']" />&nbsp;Phone not verified</span>
            </div>

            <!-- If only phone verified -->
            <div v-else-if="profile.phone_verified">
              <span><font-awesome-icon style="color: gray" :icon="['fas', 'times']" />&nbsp;Email not verified</span>
              <span><font-awesome-icon style="color: green" :icon="['fas', 'check']" />&nbsp;Phone verified</span>
            </div>

            <!-- Nothing verified -->
            <div v-else>
              <span><font-awesome-icon style="color: gray" :icon="['fas', 'times']" />&nbsp;Email & phone not verified</span>
            </div>
            </div>
          </div>

          <div v-if="profile.carpooling_details" class="carpool-profile-description">
            <span v-if="!read_more_activated && profile.carpooling_details.description.length > max_description_length">{{ (profile.carpooling_details.description || '').slice(0, max_description_length).trim() }}... <a @click="read_more_activated = true" class="highlighted"> read more</a></span>
            <span v-else>{{ profile.carpooling_details.description }}</span>
            <div v-if="profile.carpooling_details && profile.carpooling_details.contribution && isNumber(profile.carpooling_details.contribution)" class="wanted-contribution">
              <span class="dark">Wanted contribution: </span><span>&euro;{{ profile.carpooling_details.contribution }}</span>
            </div>
            <div v-if="profile.carpooling_friends && profile.carpooling_friends[$store.getters.user.id] == 'accepted'" class="contact-details">
              <span class="dark">Contact:</span>
              <div><span>{{ phone_number.formatNational() }}</span><br><span>{{ profile.email }}</span></div>
            </div>

          </div>

          <div class="carpooling-profile-details">
            <div class="details-days-of-week">
              <span :day-active="profile.commuting_days_of_week.monday">Mon</span><span :day-active="profile.commuting_days_of_week.tuesday">Tue</span><span :day-active="profile.commuting_days_of_week.wednesday">Wed</span><span :day-active="profile.commuting_days_of_week.thursday">Thu</span><span :day-active="profile.commuting_days_of_week.friday">Fri</span><span :day-active="profile.commuting_days_of_week.saturday">Sat</span><span :day-active="profile.commuting_days_of_week.sunday">Sun</span>
            </div>

            <div class="details-times">
              <div>
                <span class="info-desc">Go</span>
                <span class="info-data">{{ go_leaving_time }}</span>
              </div>

              <div>
                <span class="info-desc">Back</span>
                <span class="info-data">{{ back_leaving_time }}</span>
              </div>
            </div>

            <div v-if="action_needed != 'none'" id="floating-button-request">
              <el-button type="primary" :class="{ 'secondary-color': action_needed != 'pending_request', 'pending-button': action_needed == 'pending_request' }" :data-action="action_needed" round>
                <div v-if="action_needed == 'send_message'" @click="onActionClicked(action_needed)" class="button-content">
                  <font-awesome-icon style="font-size: 18px; margin-right: 3px" :icon="['fab', 'whatsapp']"/>
                  <span>&nbsp;Send WhatsApp</span>
                </div>

                <div v-if="action_needed == 'send_request'" @click="onActionClicked(action_needed)" class="button-content">
                  <font-awesome-icon style="font-size: 16px; margin-right: 3px" :icon="['fas', 'user-plus']"/>
                  <span>&nbsp;Send request</span>
                </div>

                <div v-if="action_needed == 'accept_request'" @click="onActionClicked(action_needed)" class="button-content">
                  <font-awesome-icon style="font-size: 16px; margin-right: 3px" :icon="['fas', 'user-check']"/>
                  <span>&nbsp;Accept request</span>
                </div>

                <div v-if="action_needed == 'pending_request'" @click="onActionClicked(action_needed)" class="button-content">
                  <font-awesome-icon style="font-size: 16px; margin-right: 3px" :icon="['fas', 'spinner']"/>
                  <span>&nbsp;Pending request</span>
                </div>
              </el-button>
            </div>

          </div>
        </div>
      </div>

      
  </div>
</template>

<script>
import initGoogleMaps from '@/utils/gmaps';
import moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';

export default {
  props: { id: String },
  data: () => ({
    profile: {
      commuting_days_of_week: {},
      carpooling_friends: {},
      commuting_times: {}
    },
    read_more_activated: false,
    max_description_length: 150,
    loading: true,
    map: null
  }),
  
  methods: {
    onActionClicked(action) {
      if (action == 'send_message') {
        if (['iPhone', 'iPad', 'iPod'].includes(navigator.platform)) {
          const win = window.open(`https://wa.me/${this.phone_number.number}?text=Hi%20there!`, '_top');
          return win.focus();
        }

        this.$analytics.logEvent("carpooling_send_message", { friend_id: this.id });

        return window.open(`https://wa.me/${this.phone_number.number}?text=Hi%20there!`);

      } else if (action == 'send_request') {
        this.loading = true;

        this.SharoAPI({ action: 'setCarpoolingFriend', data: { _id: this.id, action: 'add' } }, true).then(result => {
          if (result.data.error && result.data.code != "AUTH_PERMISSION_DENIED") {
            return this.$message({ type: 'error', showClose: true, message: result.data.error_message });
          }

          // If AUTH_PERMISSION_DENIED
          if (result.data.error && result.data.code == "AUTH_PERMISSION_DENIED") {
            return this.$router.push({ name: result.redirect_name, query: { verifications: result.redirect_verifications, startTitle: '', startMessage: `In order to send a request to ${this.profile.first_name}, we need to quickly verify your profile.` }});
          }

          this.$message({ type: 'success', showClose: true, message: result.data.message });

          // Analytics
          this.$analytics.logEvent("carpooling_send_request", { friend_id: this.id });

          this.getCarpoolingProfile();
        }).finally(_ => this.loading = false);
        
      } else if (action == 'accept_request') {
        this.loading = true;
        
        this.SharoAPI({ action: 'setCarpoolingFriend', data: { _id: this.id, action: 'accept' } }, true).then(result => {
          // If AUTH_PERMISSION_DENIED
          if (result.data.error && result.data.code == "AUTH_PERMISSION_DENIED") {
            return this.$router.push({ name: result.redirect_name, query: { verifications: result.redirect_verifications, startTitle: '', startMessage: `In order to accept ${this.profile.first_name}'s request, we need to quickly verify your profile.` }});
          }

          if (result.data.error) {
            return this.$message({ type: 'error', showClose: true, message: result.data.error_message });
          }

          this.$message({ type: 'success', showClose: true, message: result.data.message });

          // Analytics
          this.$analytics.logEvent("carpooling_accept_request", { friend_id: this.id });
          
          this.getCarpoolingProfile();
        }).finally(_ => this.loading = false);
      } else if (action == 'pending_request') {
        return this.$message({ type: 'info', showClose: true, message: `${this.profile.first_name} hasn't accepted your request yet` });
      } else {

      }
    },

    isNumber(number) {
      return this.isType('number', number);
    },

    async getCarpoolingProfile() {
      let sharoResult = await this.SharoAPI({ action: 'getPublicCarpoolingProfile', data: { _id: this.id }})
      
      if (sharoResult.data.error) {
        this.$message({ type: 'error', showClose: true, message: 'Could not get profile' });
        return this.$router.go(-1);
      }

      this.profile = sharoResult.data.profile;

      return sharoResult;
    }
  },

  async mounted() {
    
    let sharoResult = await this.getCarpoolingProfile();
    
    let google = await initGoogleMaps();

    this.map = new google.maps.Map(this.$refs.google_map, {
      zoom: 12,
      center: { lat: sharoResult.data.profile.address.address_location.lat, lng: sharoResult.data.profile.address.address_location.lng },
      // mapTypeId: 'satellite',
      disableDefaultUI: true
    });

    this.SharoAPI({ action: 'corsAnywhere', data: `https://maps.googleapis.com/maps/api/directions/json?origin=${sharoResult.data.profile.address.address_location.lat},${sharoResult.data.profile.address.address_location.lng}&destination=${sharoResult.data.profile.office_location.lat},${sharoResult.data.profile.office_location.lng}&mode=drive&key=AIzaSyApGepgI3SX24dwNQ0o2MUJBemf0MXvKVk&alternatives=true` }).then(result => {
      console.log(result);
      let r = result.data.result;

      let decoded = google.maps.geometry.encoding.decodePath(r.routes[0].overview_polyline.points);

      var polyline = new google.maps.Polyline({
        path: decoded,
        map: this.map,
        geodesic: true,
        strokeColor: '#669DF6',
        strokeOpacity: 1.0,
        strokeWeight: 7
      });

      var home_marker = new google.maps.Marker({
        map: this.map,
        position: new google.maps.LatLng(this.user.address.address_location.lat, this.user.address.address_location.lng),
        title: 'Home',
        icon: process.env.BASE_URL + 'img/map-icons/home.png'
      });

      var office_marker = new google.maps.Marker({
        map: this.map,
        position: new google.maps.LatLng(sharoResult.data.profile.office_location.lat, sharoResult.data.profile.office_location.lng),
        title: 'Home',
        icon: process.env.BASE_URL + 'img/map-icons/office.png'
      });

      var big_circle = new google.maps.Circle({
        strokeColor: '#adadad',
        strokeOpacity: 0.8,
        strokeWeight: 0.5,
        map: this.map,
        radius: 2500,    // metres
        fillColor: '#bfbfbf',
        center: { lat: sharoResult.data.profile.address.address_location.lat, lng: sharoResult.data.profile.address.address_location.lng }
      });

      var small_circle = new google.maps.Circle({
        strokeColor: '#669DF6',
        strokeOpacity: 0.8,
        strokeWeight: 1,
        map: this.map,
        radius: 1100,    // metres
        fillColor: '#669DF6',
        center: { lat: sharoResult.data.profile.address.address_location.lat, lng: sharoResult.data.profile.address.address_location.lng }
      });
    });

    this.loading = false;
  },


  computed: {
    user_photo() {
      return this.getUserPhoto(this.id, '128');
    },

    user() {
      return this.$store.getters.user.data;
    },

    go_leaving_time() {
      return moment(this.profile.commuting_times.go_leaving_time).format('LT')
    },

    back_leaving_time() {
      return moment(this.profile.commuting_times.back_leaving_time).format('LT')
    },

    email_phone_text() {     
      if (this.profile.email_verified && this.profile.phone_verified) {
        return 'Email & phone verified';
      } else if (this.profile.email_verified) {
        return 'Email verified';
      } else {
        return 'Email not verified';
      }
    },

    action_needed() {
      if (this.id == this.$store.getters.userAuth.uid) {
        return 'none';
      }
      
      if ('carpooling_friends' in this.profile && this.profile.carpooling_friends[this.$store.getters.userAuth.uid] == 'accepted') {
        return 'send_message';
      } else if (this.$store.getters.user.data.carpooling_friends && this.$store.getters.user.data.carpooling_friends[this.id] && this.$store.getters.user.data.carpooling_friends[this.id] == 'pending') {
        return 'accept_request';
      }

      if (!('carpooling_friends' in this.profile) || !this.profile.carpooling_friends[this.$store.getters.userAuth.uid]) {
        return 'send_request';
      }

      if ('carpooling_friends' in this.profile && this.profile.carpooling_friends[this.$store.getters.userAuth.uid] == 'pending') {
        return 'pending_request';
      }

    },

    phone_number() {
      return parsePhoneNumberFromString(this.profile.phone_number)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/theme/variables.scss";

  #carpooling_profile_page {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 120px);
  }

  #google_map {
    width: 100%;
    min-height: 300px;
    flex: 1;
  }

  #info_route {
    // border: 1px solid blue;
  }

  .contact-details, .wanted-contribution {
    margin-top: 10px;

    .dark {
      font-weight: 500;
      margin-right: 3px;
    }
  }

  .carpool-profile-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .carpool-profile-info-info {
      margin-left: 10px;

      & > span:first-child {
        font-size: 20px;
        display: block;
      }

      & > div > span {
        display: block;
        margin-top: -2px;
      }
    }
  }

  .carpool-profile-description {
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    margin-bottom: 10px;
  }

  .details-times {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 5px;

    & > div {
      text-align: center;
    }

    span {
      display: block;
    }

    .info-desc {
      font-size: 14px;
      margin-bottom: -5px;
    }

    .info-data {
      font-size: 22px;
    }
  }

  .details-days-of-week {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    span {
      display: block;
      margin: 4px;
      font-size: 16px;
      color: rgb(200, 200, 200);

      &[day-active="true"] {
        color: #13b500;
      }
    }
  }

  #floating-button-request {
    width: 100%;
    margin-top: 20px;
    text-align: center;

    .pending-button {
      background-color: #aaaaaa !important;
      border-color: #aaaaaa !important;
      span {
        color: white !important;
      }

      svg {
        color: white !important;
      }
    }
  }

  .button-content {
    display: flex;
    align-items: center;
  }

</style>